import React, {useState} from 'react';
import ReactGA from 'react-ga4';
import { SsvContext } from '../../context/context';
import SectionHeader from '../../Components/Static/SectionHeader';
import { useContext } from 'react';
import { Button } from '../../Components/Button/Button';
import { getParsedDateCalendar } from '../../functions/getParsedDateCalendar';
import { useEffect } from 'react';
import Loader from '../../Components/Loader/Loader';


const blogpictureurl = '../../../images/blog/';

export const Blog = () => {
  const { blog } = useContext(SsvContext);
  const [loaded, setLoaded] = useState(false);
  setTimeout(() => {
    setLoaded(true);
  }, 500);
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: `/blog` });
  }, []);
  return (
    <>
      { blog ? (
        <>
          {loaded ? (
            <>
              <SectionHeader name='Blog' />
              {blog.map((item) => (
                <>
                  {item.active === true ? (
                    <div className='mb-4 border-t border-ssv_softblack' key={JSON.stringify(item)}>
                    <article className='flex flex-col md:flex-row p-0.5'>
                      <div className='w-2/3 flex flex-col justify-between'>
                        <div>
                          <h2 className='font-ebgaramond text-2xl font-bold mb-4'>
                            {item.title}
                          </h2>
                          <time className='font-montserrat text-sm'>
                            {getParsedDateCalendar(item.date)}
                          </time>
                        </div>
                        <Button
                          title='Leer artículo'
                          destino={`${item.slug}`}
                          type='primary'
                          className='mb-1'
                          destinytitle={`${item.title}`}
                        />
                      </div>
                      <div className='w-1/3 flex justify-end'>
                        <img
                          src={`${blogpictureurl}${item.picture[0].picurl}`}
                          alt={item.picture[0].picauthor}
                          className='w-96'
                        />
                      </div>
                    </article>
                     </div>
                  ) : null}
                </>
              ))}
            </>
          ) : (
            <Loader loaded={loaded} />
          )}
        </>
      ) : null}

    </>
  );
};
export default Blog;
