import React, { useState } from 'react';
import { useContext } from 'react';
import { SsvContext } from '../../context/context';
import { Button } from '../../Components/Button/Button';
import SectionHeader from '../../Components/Static/SectionHeader';
import ReactGA from 'react-ga4';
import { getParsedDateCalendarPage } from './getParsedDateCalendarPage';
import { useEffect } from 'react';
import Loader from '../../Components/Loader/Loader';

const imagesurl = '../../../images/meetings/';

const Calendar = () => {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: `/calendario` });
  }, []);
  const { meetings } = useContext(SsvContext);
  let [loaded, setLoaded] = useState(true);
  setTimeout(() => {
    setLoaded(true);
  }, 1000);
  return (
    <>
      {meetings && loaded && (
        <>
              <SectionHeader name='Semana Santa 2024' />
              <ul className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-5'>
                {meetings
                  .sort((a, b) => {
                    return new Date(a.date) - new Date(b.date);
                  })
                  .map((meet, index) => {
                    return (
                      <>
                        <li
                          className='w-full drop-shadow-card-ssv'
                          key={JSON.stringify(meet)}>
                          <div
                            className='h-32 md:h-64 p-4 bg-cover'
                            style={{
                              backgroundImage:
                                'url(' +
                                imagesurl +
                                meet.slug +
                                '/' +
                                meet.picture[0].picurl +
                                ')',
                            }}>
                            <div className='bg-ssv_yellow w-fit h-auto text-black drop-shadow-card-ssv p-3 flex flex-col text-center'>
                              <time className='font-ebgaramond text-xs'>
                                {getParsedDateCalendarPage(meet.date)[0]}
                              </time>
                              <time className='font-ebgaramond text-4xl'>
                                {getParsedDateCalendarPage(meet.date)[1]}
                              </time>
                              <time className='font-ebgaramond text-xs'>
                                {getParsedDateCalendarPage(meet.date)[2]}
                              </time>
                              {/*
                               */}
                            </div>
                          </div>
                          <div className='bg-black p-2 md:p-4 flex justify-end'>
                            <Button
                              title={meet.dayname}
                              destino={meet.slug}
                              type='secondary'
                              className=''></Button>
                          </div>
                        </li>
                      </>
                    );
                  })}
              </ul>
            </>
        )}
      {!loaded && <Loader loaded={loaded} />}
    </>
  );
};

export default Calendar;
