import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import Hero from '../../Components/Hero/Hero';
import NewsHome from '../../Components/NewsHome/NewsHome';
import ProcessionsHome from '../../Components/ProcessionsHome/ProcessionsHome';
import ReactGA from 'react-ga4';
import { SsvContext } from '../../context/context';

const Home = () => {
  const { lastnews, nextprocessions, heroimages } = useContext(SsvContext);
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: `/` });
  }, []);
  return (
    <>
      <h1 className='font-ebgaramond text-3xl my-8 p-2'>
        Semana Santa Valladolid
      </h1>
      <Hero heroimages={heroimages} />
      <div className='flex flex-col'>
        <section>
          <div className='container mx-auto px-4 flex flex-col'>
            <h2 className='font-ebgaramond text-3xl my-8'>
              Próximas procesiones
            </h2>
            <div className=''>
              <ProcessionsHome processions={nextprocessions} />
            </div>
          </div>
        </section>
        <section>
          <div className='container mx-auto px-4 flex flex-col'>
            <h2 className='font-ebgaramond text-3xl my-8'>Últimas noticias</h2>
            <NewsHome news={lastnews} />
            <div className=''></div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
