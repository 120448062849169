import React from 'react';

const SectionHeader = (props) => {
  return (
    <div className={`mx-auto py-2 md:py-4 ${props.className || ''}`}>
      <h1 className='text-3xl md:text-5xl pb-2 md:pb-4 font-ebgaramond text-ssv_softblack md:leading-relaxed'>
        {props.name}
      </h1>
    </div>
  );
};

export default SectionHeader;
