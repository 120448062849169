import { Link } from 'react-router-dom';
import logo from './logo_dark.svg';

const Logo = () => {
  return (
    <Link to='/' className='flex items-center self-start'>
      <img src={logo} alt='Logo Semana Santa Valladolid' className='h-20 md:h-28'/>
      {/* <span className='font-ebgaramond text-lg sm:text-2xl md:text-3xl lg:text-3xl leading-4 sm:leading-5 md:leading-6 lg:leading-7 text-ssv_softblack' >
        Semana
        <br />
        Santa
        <br />
        Valladolid
      </span> */}
    </Link>
  );
};

export default Logo;
