import React from 'react';
import { useContext } from 'react';
import { SsvContext } from '../../context/context';
import ReactGA from 'react-ga4';
import SectionHeader from '../../Components/Static/SectionHeader';
import { getParsedDateCalendar } from '../../functions/getParsedDateCalendar';
import { useEffect } from 'react';
import './news.css';
import { Link } from 'react-router-dom';
const newspictureurl = '../../../images/noticias/';

const News = () => {
  const { news } = useContext(SsvContext);
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/noticias' });
  }, []);
  return (
    <>
      <SectionHeader
        name='Noticias'
        className='contenedor px-0 md:px-0 py-0 md:py-0 m-0'
      />
      <div className='contenedor px-4'>
        {news
          .sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          })
          .map((item, index) => (
            <>
              {item.active === true ? (
                <div
                  className={`newscontainer${index}`}
                  key={JSON.stringify(item)}>
                  <article className='flex flex-col'>
                    {index <= 1 ? (
                      <>
                        <div
                          style={{
                            backgroundImage: `url("${newspictureurl}${item.slug}/${item.slug}_01.jpeg")`,
                          }}
                          className='grid justify-items-center bg-blend-luminosity'>
                          <img
                            src={`${newspictureurl}${item.slug}/${item.slug}_01.jpeg`}
                            alt={item.title}
                            className='h-44 md:h-[28rem]'
                          />
                        </div>
                        <Link
                          to={item.slug}
                          className='font-ebgaramond text-4xl font-bold my-2 hover:underline'>
                          {item.title}
                        </Link>
                      </>
                    ) : (
                      <>
                        <img
                          src={`${newspictureurl}${item.slug}/${item.slug}_01.jpeg`}
                          alt={item.title}
                          className='w-full object-cover h-32'
                        />
                        <Link
                          to={item.slug}
                          className='font-ebgaramond text-xl font-bold my-2 hover:underline'>
                          {item.title}
                        </Link>
                      </>
                    )}
                    <p className='pb-2 mb-4 border-b border-ssv_yellow'>
                      {item.subtitle}
                    </p>
                    <time className='font-montserrat text-sm'>
                      {getParsedDateCalendar(item.date)}
                    </time>
                    {/* <Button
                      title='Leer noticia'
                      destino={`${item.slug}`}
                      type='primary'
                      className='mb-1'
                      destinytitle={`${item.title}`}
                    /> */}
                  </article>
                </div>
              ) : null}
            </>
          ))}
      </div>
    </>
  );
};

export default News;
