import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Button } from '../../Components/Button/Button';
import SectionHeader from '../../Components/Static/SectionHeader';
import Loader from '../../Components/Loader/Loader';

const BASEURL = 'https://ssvback.vercel.app';
//const BASEURL = 'http://localhost:8001';
const imagesurl = '../../../images/procesiones/';
const CalendarDetail = () => {
  const { name } = useParams();

  document.title = `${name}`;
  const [meet, setMeet] = useState({});
  console.log("esto es meet", meet)
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const getCalendarByName = async () => {
      const res = await axios.get(`${BASEURL}/calendario/${name}`);
      setMeet(res.data.meetings[0]);
      setTimeout(() => {
        setLoaded(true);
      }, 500);
    };
    getCalendarByName();
  }, [name]);

  function getParsedDate(date) {
    date = String(date).split(' ');
    let days = String(date[0]).split('-');
    const fecha =
      parseInt(days[2]) + '/' + parseInt(days[1]) + '/' + parseInt(days[0]);
    return fecha;
  }
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: `/calendario/${name}` });
  }, [name]);

  return (
    <>
      {meet ? (
        <>
          {loaded ? (
            <>
            <div className='flex'> 
              <aside className='md:w-1/3 p-4 md:border-r border-black order-2 md:order-1'>
                <div></div>
                <h3 className='text-ssv_softblack font-ebgaramond text-xl font-bold leading-10 mb-2'>
                  Procesiones
                </h3>
                {meet.processions.length === 0 ? (
                  <p>No hay procesiones programadas para este día</p>
                ) : (
                  <ul className=''>
                    {meet.processions.map((item) => {
                      return (
                        <>
                          {item.active ? (
                            <li className='text-black' key={JSON.stringify(item)}>
                              <span>{item.hour}:</span>
                              <Link
                                to={`../procesiones/${item.slug}`}
                                className='underline'>
                                {item.processionname}
                              </Link>
                            </li>
                          ): null }
                        </>
                      );
                    })}
                  </ul>
                )}
              </aside>
              <div className='md:w-2/3 p-4 order-1 md:order-2'>
                <section className='border-b border-black'>
                  <SectionHeader
                    name={meet.dayname}
                    className={'text-black p-0 m-0'}
                  />
                  <time className='text-black px-4'>
                    {getParsedDate(meet.date)}
                  </time>
                  <div className='container mx-auto mb-8 flex justify-end'>
                    <Button
                      title='Volver al calendario'
                      destino={'/calendario'}
                      type='primary'
                      className='mb-1'
                    />
                  </div>
                </section>
                <div className='flex p-2 md:p-4'>{meet.desc}</div>
                <div className='flex flex-col md:flex-row max-w-max overflow-y-scroll'>
                  {meet.processions.map ((procInMeet, index) => (
                    <div className='md:w-1/3 p-4' key={JSON.stringify(procInMeet)}>
                    <img src={`${imagesurl}${procInMeet.slug}/${procInMeet.slug}-0${1}.jpeg`} alt={procInMeet.processionname} />
                      <p>{procInMeet.processionname}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            </>
          ) : (
            <Loader loaded={loaded} />
          )}
        </>
      ) : null}
    </>
  );
};

export default CalendarDetail;
