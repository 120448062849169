import React, { useState, useContext, useEffect } from 'react';
import { SsvContext } from '../../context/context';
import ReactGA from 'react-ga4';
import SectionHeader from '../../Components/Static/SectionHeader';
import { Button } from '../../Components/Button/Button';
import Loader from '../../Components/Loader/Loader';

const imagesurl = '../../../images/procesiones/';

const Processions = () => {
  const { processions } = useContext(SsvContext);
  const [loaded, setLoaded] = useState(false);
  const [orden, setOrden] = useState('ascendente');

  setTimeout(() => {
    setLoaded(true);
  }, 500);
  function getParsedDateCalendar(date) {
    date = String(date).split(' ');
    const monthNames = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    let days = String(date[0]).split('-');
    const dayCalendar = parseInt(days[2]);
    const mesCalendar = monthNames[parseInt(days[1] - 1)];
    const yearCalendar = parseInt(days[0]);
    //const fecha = monthNames[parseInt(days[1])] + '/' + parseInt(days[2]);
    const fechatotal = [dayCalendar, mesCalendar, yearCalendar];
    return fechatotal;
  }
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: `/procesiones/` });
  }, []);

  const handleOrdenChange = (event) => {
    setOrden(event.target.value);
  };

  const renderProcessions = () => {
    return processions
      .filter((procession) => procession.active)
      .sort ((a, b) =>
      orden === 'ascendente'
      ? a.processionname.localeCompare(b.processionname)
      : b.processionname.localeCompare(a.processionname)
      )
      .map((procession) => (
        <article className='shadow-lg mb-4 flex flex-col rounded-lg content-between p-6' key={JSON.stringify(procession)}>
        <div className='divide divide-black mb-3'>
          <h2 className='font-ebgaramond mb-3'>{procession.processionname}</h2>
          <hr className='mb-3' />
        </div>
        <div className='flex flex-col justify-between md:px-4 rounded-lg border-yellow-500 border p-4 mb-4'>
          <h3 className='text-ssv_softblack font-ebgaramond text-md mb-2 flex flex-col'>
            <span className='text-sm'>Dia: 
              {getParsedDateCalendar(procession.date)[0]}/
              {getParsedDateCalendar(procession.date)[1]}/
              {getParsedDateCalendar(procession.date)[2]}
            </span>
          </h3>
          <h3 className='text-ssv_softblack font-ebgaramond text-md flex flex-col'> 
            <span className='text-sm'>Hora: <time>{procession.hour}</time></span>
          </h3>
        </div>
        <figure className='mb-4'>
          <img 
            src={`${imagesurl}${procession.slug}/${procession.picture[0].picurl}`}
            alt={procession.picture[0].pictitle}
            className='object-cover'
          />
          {procession.picture[0].picauthor ? (
            <figcaption className='text-sm my-2'>
              Autor de la fotografía:{' '}
              <em>{procession.picture[0].picauthor}</em>
            </figcaption>
          ) : null}
        </figure>
        <div className='flex justify-end'>
        <Button
          title={`Ver ${procession.processionname}`}
          destino={`${procession.slug}`}
          type='primary'
          className='mb-1'
        />
        </div>
      </article>
    ))
  }

  return (
    <>
      {processions && loaded && (
        <>
          <SectionHeader name='Procesiones' />
          <div className='flex justify-end mb-4'>
            <select value={orden} onChange={handleOrdenChange} className='p-4' style={{ backgroundColor: 'transparent' }}>
              <option value="ascendente">Orden Ascendente</option>
              <option value="descendente">Orden Descendente</option>
            </select>
          </div>
          <div className='grid grid-cols-1 md:gird-cols-2 lg:grid-cols-3 gap-9'>
            {renderProcessions()}
          </div>
        </>
      )}
      <Loader loaded={loaded} />
    </>
  );
};

export default Processions;
