import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../Button/Button';
import { getParsedDateCalendar } from '../../functions/getParsedDateCalendar';
import { FaCalendarAlt } from 'react-icons/fa';
import Loader from '../Loader/Loader';

const ProcessionsHome = ({ processions }) => {
  const [loaded, setLoaded] = useState(false);
  setTimeout(() => {
    setLoaded(true);
  }, 500);
  return (
    <>
      {processions ? (
        <>
          {loaded ? (
            <>
              <div className='mb-4 flex flex-col md:flex-row justify-between'>
                {processions.map((item) => (
                  <article
                    className='flex flex-col md:flex-col justify-between content-between drop-shadow-card-ssv bg-white rounded-lg p-4 m-4'
                    key={item._id}>
                    <div className='flex flex-row md:flex-col'>
                      <div
                        className='flex flex-col md:flex-row pb-4 h-auto mx-2 md:h-12 items-center'>
                        <FaCalendarAlt className='self-center fill-ssv_yellow mb-2 md:mr-4' />
                        <hr className='h-full w-px md:w-full md:h-px bg-ssv_yellow' />
                      </div>
                      <div className='flex flex-col'>
                        <p className='text-ssv_softblack w-full font-montserrat  pb-4'>
                          <time dateTime={getParsedDateCalendar(item.date)}>
                            {getParsedDateCalendar(item.date)}
                          </time>
                        </p>
                        <p className='text-ssv_softblack w-full font-montserrat pb-4'>
                          <Link
                            className='underline'
                            to={`procesiones/${item.slug}`}>
                            <h2>{item.processionname}</h2>
                          </Link>
                        </p>
                      </div>
                    </div>
                  </article>
                ))}
              </div>
              <div className='flex justify-end'>
                <Button
                  title='Ver procesiones'
                  destino={`../procesiones/`}
                  type='primary'
                />
              </div>
            </>
          ) : (
            <Loader loaded={loaded} />
          )}
        </>
      ) : null}
    </>
  );
};

export default ProcessionsHome;
