import React from 'react';
import { useContext } from 'react';
import { SsvContext } from '../../context/context';
import { Button } from '../Button/Button';
//import { FaFacebookF } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaUserAlt } from 'react-icons/fa'
//import { FaInstagram } from 'react-icons/fa';
//import { FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  const { brotherhoods, meetings } = useContext(SsvContext);
  return (
    <footer className='text-white bg-black body-font'>
      <div className='container px-5 md:py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col'>
        <div className='flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 text-left'>
          <div className='lg:w-1/4 md:w-1/2 w-full px-2 md:px-4'>
            <h2 className='font-ebgaramond text-white border-b-2 border-ssv_yellow pb-4 mb-4 '>
              Semana Santa Valladolid
            </h2>
            <nav className='list-none mb-8'>
              {meetings.sort((a, b) => {
                return new Date(a.date) - new Date(b.date);
              }).map((meeting) => {
                return (
                  <li className='my-4' key={JSON.stringify(meeting)}>
                    <a
                      className='text-white font-montserrat'
                      href={`/calendario/${meeting.slug}`}>
                      {meeting.dayname}
                    </a>
                  </li>
                );
              })}
            </nav>
            <Button
              title='Procesiones'
              destino={`procesiones`}
              type='secondary'
              className='mb-8'></Button>
            <Button
              title='Pasos'
              destino='pasos'
              type='secondary'
              className='mb-8'></Button>
            <Button
              title='Imagineros'
              destino='imagineros'
              type='secondary'
              className='mb-8'></Button>
            <Button
              title='Noticias'
              destino='noticias'
              type='secondary'
              className='mb-8'></Button>
            <Button
              title='Blog'
              destino='blog'
              type='secondary'
              className='mb-8'></Button>
          </div>
          <div className='w-full md:w-1/2 lg:w-3/4 px-4'>
            <h2 className='font-ebgaramond text-white border-b-2 border-ssv_yellow pb-4 mb-4'>
              Cofradías
            </h2>
            <nav className='list-none mb-10'>
              {brotherhoods.map((brotherhood) => (
                <>
                  {brotherhood.active === true ? (
                    <li className='my-4' key={JSON.stringify(brotherhood)}>
                      <a
                        className='text-white font-montserrat'
                        href={`../cofradias/${brotherhood.slug}`}>
                        {brotherhood.name}
                      </a>
                    </li>
                  ) : null}
                </>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className='bg-gray-100'>
        <div className='container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row'>
          <p className='text-gray-500 text-sm text-center sm:text-left'>
            © 2023 Semana Santa Valladolid —
            <a
              href='https://twitter.com/recursosux'
              rel='noopener noreferrer'
              className='text-gray-600 ml-1'
              target='_blank'>
              @recursosux
            </a>
          </p>
          <span className='inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start'>
            {/* Enlace a facebook */}
            {/* <a className='text-gray-500' href='www.google.com'>
              <FaFacebookF/>
            </a> */}
            {/* Enlace a twitter */}
            <a
              className='ml-3 text-gray-500'
              href='https://twitter.com/recursosux'>
              <FaTwitter />
            </a>
            {/* Enlace a Instagram */}
            {/* <a className='ml-3 text-gray-500' href='www.google.com'>
              <FaInstagram/>
            </a> */}
            {/* Enlace a Portfolio */}
            <a className='ml-3 text-gray-500' href='https://www.diegocastillo.es/portfolio/' target='_blank' rel="noreferrer"><FaUserAlt/></a>
            
            {/* Enlace a Linkedin */}
            {/* <a className='ml-3 text-gray-500' href='www.google.com'>
              <FaLinkedin />
            </a> */}
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
