import React from 'react';

const SuperHeader = () => {
  return (
    <section className='bg-ssv_yellow p-1 text-center text-ssv_softblack'>
      <div className='mx-auto max-w-screen-xl'>
        {/* <a className='text-xs md:text-md' href='mailto:info@semanasantavalladolid.com'>
          info@semanasantavalladolid.com
        </a> */}
      </div>
    </section>
  );
};

export default SuperHeader;
