import React, { useState } from 'react';
import { Button } from '../Button/Button';
import { getParsedDateCalendar } from '../../functions/getParsedDateCalendar';
import Loader from '../Loader/Loader';
import parse from 'html-react-parser';
const imagesurl = '../../../images/noticias/';

const NewsHome = ({ news }) => {
  const [loaded, setLoaded] = useState(false);
  setTimeout(() => {
    setLoaded(true);
  }, 500);
  return (
    <>
      {news ? (
        <>
          {loaded ? (
            <>
              <section className='mb-4 flex flex-col lg:flex-row  justify-center'>
                {news
                  .sort((a, b) => {
                    return new Date(b.date) - new Date(a.date);
                  })
                  .map((item) => (
                    <>
                      {item.active === true ? (
                        <article
                          key={JSON.stringify(item)}
                          className='flex border-ssv_yellow border-2 flex-col p-6 m-4 lg:w-1/3 '>
                          <div className='w-full mb-4'>
                            <img
                              src={`${imagesurl}${item.slug}/${item.picture}_01.jpeg`}
                              alt={item.title}
                              className='grayscale hover:grayscale-0 w-full object-cover bg-blend-color md:h-96'
                            />
                          </div>
                          <div className='w-full'>
                            <h2 className='text-ssv_softblack font-bold text-base font-montserrat mb-2 border-b pb-4 border-ssv_yellow'>
                              {item.title}
                            </h2>
                            <p className='text-ssv_softblack font-bold text-sm mb-4'>
                              <time dateTime={getParsedDateCalendar(item.date)}>
                                {getParsedDateCalendar(item.date)}
                              </time>
                            </p>
                            <div className='font-montserrat text-base mb-8 h-12 overflow-y-hidden'>
                              {parse(item.content)}
                            </div>
                            <div className='flex justify-end'>
                              <Button
                                title='Leer noticia'
                                destino={`../noticias/${item.slug}`}
                                type='primary'
                              />
                            </div>
                          </div>
                        </article>
                      ) : null}
                    </>
                  ))}
              </section>
            </>
          ) : (
            <Loader loaded={loaded} />
          )}
        </>
      ) : null}
    </>
  );
};
export default NewsHome;
