import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';
import axios from 'axios';
import SectionHeader from '../../Components/Static/SectionHeader';
import { Button } from '../../Components/Button/Button';
import parse from 'html-react-parser';
import Loader from '../../Components/Loader/Loader';

const newspictureurl = '../../../images/noticias/';

const BASEURL = 'https://ssvback.vercel.app';
//const BASEURL = 'http://localhost:8001';

const NewsDetail = () => {
  const { slug } = useParams();
  document.title = `${slug}`;
  const [newsArticle, setNewsArticle] = useState({});
  const [loaded, setLoaded] = useState(false);
  setTimeout(() => {
    setLoaded(true);
  }, 500);
  useEffect(() => {
    const getNewsBySlug = async () => {
      const res = await axios.get(`${BASEURL}/noticias/${slug}`);
      setNewsArticle(res.data.news[0]);
      setTimeout(() => {
        setLoaded(true);
      }, 500);
    };
    getNewsBySlug();
  }, []);
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: `/noticias/${slug}` });
  }, [slug]);
  return (
    <>
      <div className='flex flex-row justify-center md:p-8 border-b-2'>
        {newsArticle ? (
          <>
            {loaded ? (
              <>
                <article className='lg:w-1/2 p-4 '>
                  <SectionHeader
                    name={newsArticle.title}
                    className={'text-black p-0 m-0'}
                  />
                  <p className='text-2xl bg-ssv_yellow p-8 font-ebgaramond my-8 rounded-md'>
                    {newsArticle.subtitle}
                  </p>
                  <figure className='flex flex-col items-center'>
                    <img
                      src={`${newspictureurl}${newsArticle.slug}/${newsArticle.picture}_01.jpeg`}
                      alt={newsArticle.title}
                      className='h-36 w-full md:h-96 md:w-96 object-cover'
                    />
                    <figcaption className='bg-ssv_yellow p-2 font-ebgaramond max-w-prose'>
                      {newsArticle.title}
                    </figcaption>
                  </figure>
                  <p className='my-8 md:p-8 text-lg font-montserrat'>
                    {parse(newsArticle.content)}
                  </p>
                  {newsArticle.destinationunrl ? (
                    <div className='border-2 p-6'>
                      <Link
                        to={newsArticle.destinationunrl}
                        className='underline'>
                        Más información
                      </Link>
                    </div>
                  ) : null}
                </article>
              </>
            ) : (
              <Loader loaded={loaded} />
            )}
          </>
        ) : null}
      </div>
      <div className='flex flex-row justify-center p-8'>
        <Button
          title='Ver más noticias'
          destino={'/noticias'}
          type='primary'
          className='mb-1'
        />
      </div>
    </>
  );
};

export default NewsDetail;
