import React, {useState} from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SsvContext } from '../../context/context';
import ReactGA from 'react-ga4';
import SectionHeader from '../../Components/Static/SectionHeader';
import { Button } from '../../Components/Button/Button';
import { useEffect } from 'react';
import Loader from '../../Components/Loader/Loader';

const Imagineros = () => {
  const { imagineros } = useContext(SsvContext);
  const [loaded, setLoaded] = useState(false);
  setTimeout(() => {
    setLoaded(true);
  }, 500);

  const { pasos } = useContext(SsvContext);
  
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: `/imagineros` });
  }, []);
  
  return (
    <>
    {imagineros ? (
      <>
        {loaded ? (
          <>
            <SectionHeader name='Imagineros' />
            {imagineros.map((item) => (
              <>
              {item.active === true ? (            
                <article className='mb-4 border-t border-ssv_softblack flex flex-col md:flex-row content-between p-0.5'>
                  <div className='text-ssv_softblack w-full md:w-1/4 p-1 mb-8'>
                    <h2 className='text-black font-ebgaramond'>
                      {item.imaginero}
                    </h2>
                    <time className='font-montserrat text-xs'>
                      {item.dateofbirth}-{item.dateofdeath}
                    </time>
                  </div>
                  <div className='w-full md:w-1/4 flex flex-col justify-between px-4 mb-8'>
                    <p className='text-black font-montserrat text-sm'>
                      {item.desc}
                    </p>
                    <Button
                      title={`Ver ${item.imaginero}`}
                      destino={item.slug}
                      type='primary'
                      className=''></Button>
                  </div>
                  <div className='w-full md:w-1/4 p-1'>
                    <h3 className='font-ebgaramond mb-4'>Obras</h3>
                    <ul className='font-montserrat text-sm pl-4'>
                      {pasos.map((work) => (
                        <>
                          {work.active
                            ? work.imagineros.map((workitem) => {
                                return (
                                  <>
                                    {workitem._id === item._id ? (
                                      <li className='mb-2'>
                                        <Link
                                          to={`../pasos/${work.slug}`}
                                          className='underline'>
                                          {work.pasoname}
                                        </Link>
                                      </li>
                                    ) : null}
                                    {/* <li key={JSON.stringify(work)}>
                          <Link to={`../pasos/${work.workname}`}>
                            {work.workname}
                          </Link>
                        </li> */}
                                  </>
                                );
                              })
                            : null}
                        </>
                      ))}
                    </ul>
                  </div>
                  <div className='w-full md:w-1/4 p-1'>
                    <figure>
                      <img
                        className='object-cover h-64 w-96'
                        src={item.picture[0].picurl}
                        alt={item.picture[0].picdesc}
                      />
                      <figcaption>
                        Autor:{' '}
                        <a
                          href={item.picture[0].picauthorurl}
                          className='underline'
                          target='_blank'
                          rel='noreferrer'>
                          {item.picture[0].picauthor}
                        </a>
                        {/* <Link to={item.picture[0].picauthor}>{item.picture[0].picauthor}</Link> */}
                      </figcaption>
                    </figure>
                  </div>
                </article>
              ): null}
              </>
            ))}
          </>
        ) : ( 
           <Loader loaded={loaded} />
        )}
      </>
    ):null}
    </>
  );
};

export default Imagineros;
