import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
//import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Button } from '../../Components/Button/Button';
import SectionHeader from '../../Components/Static/SectionHeader';
import Loader from '../../Components/Loader/Loader';
import { getParsedDateCalendarPage } from '../Calendar/getParsedDateCalendarPage';

const imagesurl = '../../../images/procesiones/';
const BASEURL = 'https://ssvback.vercel.app';
//const BASEURL = 'http://localhost:8001';

const ProcessionDetail = () => {
  const { name } = useParams();
  document.title = `${name}`;
  const [procesion, setProcesion] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getProcessionByName = async () => {
      const res = await axios.get(`${BASEURL}/procesiones/${name}`);
      setProcesion(res.data.procession[0]);
      setTimeout(() => {
        setLoaded(true);
      }, 1000);
    };
    getProcessionByName();
  }, [name]);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: `/procesiones/${name}` });
  }, [name]);
  return (
    <>
      <div className='flex flex-col-reverse md:flex-row min-h-screen p-2md:p-8'>
        {procesion ? (
          <>
            {loaded ? (
              <>
                <aside className='md:w-1/3 p-4 md:border-r md:border-black'>
                  <div>
                    <h3 className='text-ssv_softblack font-ebgaramond font-bold text-xl leading-10 mb-2 hidden sm:block'>
                      {procesion.processionname}
                    </h3>
                    <p className='font-montserrat text-sm mb-8 text-sv-softblack'>Día: 
                    {getParsedDateCalendarPage(procesion.date)[1]}-
                    {getParsedDateCalendarPage(procesion.date)[0]}-         {getParsedDateCalendarPage(procesion.date)[2]}</p>
                    <p className='font-montserrat text-sm mb-8 text-sv-softblack'>Hora: 
                    {procesion.hour}</p>
                    {procesion.detail ? (
                    <p className='font-montserrat text-sm mb-8 text-ssv_softblack'>
                      {procesion.description}
                    </p>
                    ):null}
                    <h3 className='text-ssv_softblack font-ebgaramond text-xl font-bold leading-10 mb-2'>
                      Pasos que procesionan
                    </h3>
                    <ul className='mb-8'>
                      {procesion.pasos
                        ? procesion.pasos.map((item) => {
                            return (
                              <li className='font-montserrat text-sm mb-8 text-ssv_softblack'>
                                <Link
                                  to={`../pasos/${item.slug}`}
                                  className='underline'>
                                  {item.pasoname}
                                </Link>
                              </li>
                            );
                          })
                        : null}
                    </ul>
                    <h3 className='text-ssv_softblack font-ebgaramond text-xl font-bold leading-10 mb-2'>
                      Recorrido
                    </h3>
                    <ul>
                      {procesion.routein.map((calle, index) => {
                        return (
                          <li
                            key={JSON.stringify(calle)}
                            className='flex flex-col gap-4 mb-4'>
                            <div className='flex flex-row gap-4 text-ssv_softblack text-sm items-center'>
                              {(calle.type === 'street') |
                              (calle.type === 'square') ? (
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 24 24'
                                  strokeWidth={1.5}
                                  stroke='currentColor'
                                  className='w-6 h-6 stroke-ssv_softbeige'>
                                  <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M15 10.5a3 3 0 11-6 0 3 3 0 016 0z'
                                  />
                                  <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z'
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 24 24'
                                  strokeWidth={1.5}
                                  stroke='currentColor'
                                  className='w-6 h-6 stroke-ssv_softbeige'>
                                  <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25'
                                  />
                                </svg>
                              )}
                              <p className='font-montserrat'>{calle.step}</p>
                              {calle.stepinfo ? (
                                <>
                                  <p className='font-montserrat text-ssv_softblack'>
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      fill='none'
                                      viewBox='0 0 24 24'
                                      strokeWidth={1.5}
                                      stroke='currentColor'
                                      className='w-4 h-4'>
                                      <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                                      />
                                    </svg>
                                  </p>
                                </>
                              ) : null}
                            </div>
                            {calle.stepinfo ? (
                              <div className='block pl-10'>
                                <p className='font-montserrat text-ssv_darkbeige text-sm'>
                                  {calle.stepinfo}
                                </p>
                              </div>
                            ) : null}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </aside>
                <div className='md:w-2/3 p-2 md:p-4'>
                  <section className='border-b border-black'>
                    <h2 className='font-ebgaramond'>
                      {/* {procesion.meeting.map((meet) => {
                        return <p>{meet.dayname}</p>;
                      })} */}
                    </h2>
                    <SectionHeader
                      name={procesion.processionname}
                      className={'text-black p-0 m-0'}
                    />
                    <div className='container mx-auto mb-8 flex justify-end'>
                      <Button
                        title='Ir a Listado de procesiones'
                        destino={'/procesiones'}
                        type='primary'
                        className='mb-1'
                      />
                    </div>
                  </section>
                  <div className='flex'>
                    {procesion.picture.map((item, index) => {
                      return (
                        <div
                          className='w-full md:w-1/3 p-4'
                          key={JSON.stringify(item)}>
                          <img
                            src={`${imagesurl}${procesion.slug}/${item.picurl}`}
                            alt={item.picdesc}
                            className='w-full md:aspect-[9/16] object-cover'
                          />
                          <figcaption className='text-sm'>
                            <p className=''>{item.pictitle}</p>
                            {item.picauthor ? (
                              <p>
                                Autor de la fotografía:{' '}
                                <em>{item.picauthor}</em>
                              </p>
                            ) : null}
                          </figcaption>
                        </div>
                      );
                    })}
                    {/*
                     */}
                  </div>
                </div>
              </>
            ) : (
              <Loader loaded={loaded} />
            )}
          </>
        ) : null}
      </div>
    </>
  );
};

export default ProcessionDetail;
