import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { SsvContext } from '../../context/context';
import { useEffect } from 'react';

const MegaMenu = () => {
  const { brotherhoods } = useContext(SsvContext);
  
  useEffect(() =>{
  }
  )
  return (
    <div
      id='mega-menu-full-cta-dropdown'
      className='mt-1 bg-white border-gray-200 shadow-sm border-y dark:bg-gray-800 dark:border-gray-600 hidden'>
      <div className='grid py-5 px-4 mx-auto max-w-screen-xl text-sm text-gray-500 dark:text-gray-400 md:grid-cols-2 md:px-6'>
        <ul
          className='space-y-6 sm:mb-6 md:mb-0'
          aria-labelledby='mega-menu-full-cta-button'>
          <li>
            <Link
              to={`/`}
              className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>
              Todas las cofradias
            </Link>
          </li>
          {brotherhoods.map((item, index) => {
            if (index <= 8) {
              return (
                <li key={JSON.stringify(item)}>
                  <Link
                    to={`cofradias/${item.name}`}
                    className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>
                    {item.name}
                  </Link>
                </li>
              );
            }
            return null;
          })}
        </ul>
        <ul className='hidden mb-6 space-y-6 md:mb-0 sm:block'>
          {brotherhoods.map((item, index) => {
            if (index > 8) {
              return (
                <li key={JSON.stringify(item)}>
                  <Link
                    to={`cofradias/${item.name}`}
                    className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>
                    {item.name}
                  </Link>
                </li>
              );
            }
            return null;
          })}
          
        </ul>
        {/* <div className='mt-4 md:mt-0'>
          <h2 className='mb-2 font-semibold text-gray-900 dark:text-white'>
            Our brands
          </h2>
          <p className='mb-2 font-light text-gray-500 dark:text-gray-400'>
            At Flowbite, we have a portfolio of brands that cater to a variety
            of preferences.
          </p>
          <a
            href='www.google.com'
            className='inline-flex items-center text-sm font-medium text-blue-600 hover:underline hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-700'>
            Explore our brands
            <span className='sr-only'>Explore our brands </span>
            <svg
              className='ml-1 w-4 h-4'
              aria-hidden='true'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                fill-rule='evenodd'
                d='M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z'
                clip-rule='evenodd'></path>
            </svg>
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default MegaMenu;
