import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ReactGA from 'react-ga4';
import SectionHeader from '../../Components/Static/SectionHeader';
import { Button } from '../../Components/Button/Button';
import Loader from '../../Components/Loader/Loader';

const shieldurl = '../../../images/escudos/';
const imagesurl = '../../../images/cofradias/';
const ropasurl = '../../../images/ropas/';

const BASEURL = 'https://ssvback.vercel.app';
//const BASEURL = 'http://localhost:8001';

const BrotherhoodsDetail = () => {
  const { name } = useParams();
  document.title = `${name}`;
  const [brotherhood, setBrotherhood] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getBrotherhoodByName = async () => {
      const res = await axios.get(`${BASEURL}/cofradias/${name}`);
      setBrotherhood(res.data.brotherhood[0]);
      setTimeout(() => {
        setLoaded(true);
      }, 500);
    };
    getBrotherhoodByName();
  }, []);
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: `/cofradias/${name}` });
  }, [name]);
  return (
    <>
      {brotherhood ? (
        <>
          {loaded ? (
            <>
              <div className='md:flex md:flex-row min-h-screen p-2 md:p-8 grid grid-col-1'>
                <aside className='md:w-1/3 md:p-4 md:pt-16 md:border-r border-black row-start-2'>
                  <div>
                    <p className='font-montserrat text-sm mb-8 text-ssv_softblack'>
                      {brotherhood.description}
                    </p>
                    <h3 className='text-ssv_softblack font-ebgaramond text-xl font-bold leading-10'>
                      Sede
                    </h3>
                    <p className='font-montserrat text-sm mb-8 text-ssv_softblack'>
                      {brotherhood.headquarters}
                    </p>
                    <h3 className='text-ssv_softblack font-ebgaramond text-xl font-bold leading-10'>
                      Uniforme:
                    </h3>
                    <div className='flex flex-row'>
                      <img
                        alt={`${brotherhood.clothesdesc}`}
                        className='w-1/3 md:w-1/3  lg:h-auto object-cover object-center brightness-110 mix-blend-multiply'
                        src={`${ropasurl}${brotherhood.clothesimg}`}
                      />
                      <p className='lg:w-2/3 font-montserrat text-sm mb-8 text-ssv_softblack'>
                        {brotherhood.clothesdesc}
                      </p>
                    </div>
                    <h3 className='font-ebgaramond text-2xl'>Web:</h3>
                    <p className='font-montserrat text-sm mb-8 text-ssv_softblack'>
                      <a href={brotherhood.weburl} className='underline'>
                        {brotherhood.weburl}
                      </a>
                    </p>
                    <h3 className='font-ebgaramond text-2xl'>Pasos:</h3>
                    <ul>
                      {brotherhood.pasos.map((paso, index) => {
                        return (
                          <li key={JSON.stringify(paso)}>
                            <a
                              href={`../pasos/${paso.slug}`}
                              className='text-ssv_softblack underline'>
                              {paso.pasoname}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </aside>
                <div className='md:w-2/3 md:p-4 row-start-1'>
                  <section className='border-b border-black grid'>
                    <SectionHeader
                      name={brotherhood.name}
                      className={'order-2 col-span-1 row-start-1'}
                    />
                    <div className='container mx-auto mb-8 flex justify-between row-start-2'>
                      <img
                        alt={`${ropasurl}${brotherhood.clothesdesc}`}
                        className='w-24 object-cover'
                        src={`${shieldurl}${brotherhood.shield}`}
                      />
                      <Button
                        title='Ir a Listado de cofradías'
                        destino={'/cofradias'}
                        type='primary'
                        className='mb-1'
                      />
                    </div>
                  </section>
                  <div className='flex flex-col md:flex-row'>
                    {brotherhood.picture.map((item, index) => {
                      return (
                        <div
                          className='md:w-1/3 p-4'
                          key={JSON.stringify(item)}>
                          <img
                            alt={brotherhood.name}
                            src={`${imagesurl}${brotherhood.slug}/${
                              brotherhood.slug
                            }-0${index + 1}.jpeg`}
                            className='w-full md:aspect-[9/16] object-cover'
                          />
                          {item.picauthor ? (
                            <p>
                              <span className='text-sm clear-right'>
                                Autor de la fotografía
                              </span>
                              : <em>{item.picauthor}</em>
                            </p>
                          ) : null}
                          <p>{item.picdesc}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Loader loaded={loaded} />
          )}
        </>
      ) : null}
    </>
  );
};

export default BrotherhoodsDetail;
