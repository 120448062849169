import React, { useState, CSSProperties } from 'react';

import { BarLoader } from 'react-spinners';

const override: CSSProperties = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
};
const Loader = (loaded) => {
  
  let [color, setColor] = useState('#f6c829');

  return (
    <section className='h-screen flex justify-center items-center'>
      <div className=''>
        <BarLoader
          color={color}
          loaded={loaded}
          cssOverride={override}
          size={350}
          aria-label='Loading Spinner'
          data-testid='loader'
        />
      </div>
    </section>
  );
};

export default Loader;
