import React, { useRef } from 'react';

import ReactGA from 'react-ga4';
import SectionHeader from '../../Components/Static/SectionHeader';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Button } from '../../Components/Button/Button';
import { useEffect } from 'react';

const Contact = () => {
  const form = useRef();
  const MySwal = withReactContent(Swal);
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: `/contacto` });
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        'service_xwsezk4',
        'template_s4bu35l',
        form.current,
        '_vlODyXJOZe4zfqas'
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
          MySwal.fire({
            title: <strong>Gracias por contactar</strong>,
            html: <i>En breves, nos pondremos en contacto contigo</i>,
            icon: 'success',
          });
        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            icon: 'error',
            title: 'Ooops...',
            text: 'Something went wrong!',
            footer: '<a href="">Why do I have this issue?</a>',
          });
        }
      );
  };
  return (
    <>
      <SectionHeader name='Contacto' />

      <form ref={form} onSubmit={sendEmail} className='w-full max-w-lg'>
        <div className='flex flex-wrap '>
          <div className='w-full mb-8'>
            <label
              for='nombre'
              className='block text-gray-700 font-ebgaramond font-bold mb-2'>
              Nombre
              <span className='obligatorio'>*</span>
            </label>
            <input
              type='text'
              name='user_name'
              id='nombre'
              required='obligatorio'
              placeholder='Escribe aquí tu nombre'
              className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
            />
            <p class='text-gray-300 text-xs italic'>Campo obligatorio</p>
          </div>
          <div class='w-full mb-8'>
            <label
              for='email'
              className='block text-gray-700 font-ebgaramond font-bold mb-2'>
              Email
              <span className='obligatorio'>*</span>
            </label>
            <input
              type='email'
              name='user_email'
              id='email'
              required='obligatorio'
              placeholder='Escribe aquí tu email'
              className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
            />
            <p class='text-gray-300 text-xs italic'>Campo obligatorio</p>
          </div>
          <div class='w-full mb-8'>
            <label
              for='mensaje'
              class='block text-gray-700 font-ebgaramond font-bold mb-2'>
              ¿Qué quieres contarnos?
            </label>
            <textarea
              name='message'
              id='mensaje'
              required='obligatorio'
              placeholder='Deja aquí tu comentario...'
              className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
            />
            <p class='text-gray-300 text-xs italic'>Campo obligatorio</p>
          </div>
        </div>
        <div class='flex flex-wrap -mx-3 mb-6'></div>
        <div>
          <Button
            title='Enviar Mensaje'
            type='submit'
            className='mb-8'
            id='enviar'
            value='send'
            name='enviar_formulario'
          />
        </div>
      </form>

      {/* <div className='lg:w-1/2 md:w-2/3 mx-auto'>
            <div className='flex flex-wrap -m-2'>
              <div className='p-2 w-1/2'>
                <div className='relative'>
                  <label for='name' className='leading-7 text-sm text-gray-600'>
                    Name
                  </label>
                  <input
                    type='text'
                    id='name'
                    name='name'
                    className='w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'
                  />
                </div>
              </div>
              <div className='p-2 w-1/2'>
                <div className='relative'>
                  <label for='email' className='leading-7 text-sm text-gray-600'>
                    Email
                  </label>
                  <input
                    type='email'
                    id='email'
                    name='email'
                    className='w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'
                  />
                </div>
              </div>
              <div className='p-2 w-full'>
                <div className='relative'>
                  <label for='message' className='leading-7 text-sm text-gray-600'>
                    Message
                  </label>
                  <textarea
                    id='message'
                    name='message'
                    className='w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out'></textarea>
                </div>
              </div>
              <div className='p-2 w-full'>
                <button className='flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg'>
                  Button
                </button>
              </div>
              <div className='p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center'>
                <a className='text-indigo-500' href='www.google.com'>
                  example@email.com
                </a>
                <p className='leading-normal my-5'>
                  49 Smith St.
                  <br />
                  Saint Cloud, MN 56301
                </p>
                <span className='inline-flex'>
                  <a className='text-gray-500' href='www.google.com'>
                    <svg
                      fill='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      className='w-5 h-5'
                      viewBox='0 0 24 24'>
                      <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                    </svg>
                  </a>
                  <a className='ml-4 text-gray-500' href='www.google.com'>
                    <svg
                      fill='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      className='w-5 h-5'
                      viewBox='0 0 24 24'>
                      <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                    </svg>
                  </a>
                  <a className='ml-4 text-gray-500' href='www.google.com'>
                    <svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      className='w-5 h-5'
                      viewBox='0 0 24 24'>
                      <rect
                        width='20'
                        height='20'
                        x='2'
                        y='2'
                        rx='5'
                        ry='5'></rect>
                      <path d='M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01'></path>
                    </svg>
                  </a>
                  <a className='ml-4 text-gray-500' href='www.google.com'>
                    <svg
                      fill='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      className='w-5 h-5'
                      viewBox='0 0 24 24'>
                      <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                    </svg>
                  </a>
                </span>
              </div>
            </div>
          </div> */}
    </>
  );
};
export default Contact;
