import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { SsvContext } from '../../context/context';
import ReactGA from 'react-ga4';
import SectionHeader from '../../Components/Static/SectionHeader';
import { Button } from '../../Components/Button/Button';
//import { DropButton } from '../../Components/Button/DropButton';
import Loader from '../../Components/Loader/Loader';

const Pasos = () => {
  const { pasos } = useContext(SsvContext);
  const [loaded, setLoaded] = useState(false);
  const [orden, setOrden] = useState('ascendente');

  useEffect(() => {
    setLoaded(true);
    ReactGA.send({ hitType: 'pageview', page: '/pasos' });
  }, []);

  const handleOrdenChange = (event) => {
    setOrden(event.target.value);
  };

  const renderPasos = () => {
    return pasos
      .filter((paso) => paso.active)
      .sort((a, b) =>
        orden === 'ascendente'
          ? a.pasoname.localeCompare(b.pasoname)
          : b.pasoname.localeCompare(a.pasoname)
      )
      .map((paso) => (
        <article className='shadow-lg mb-4 flex flex-col rounded-lg content-between p-6' key={JSON.stringify(paso)}>
          <div className='divide divide-black mb-3'>
            <h2 className='font-ebgaramond mb-3'>{paso.pasoname}</h2>
            <hr className='mb-3' />
          </div>
          <div className='flex flex-col justify-between md:px-4 rounded-lg border-yellow-500 border p-4 mb-4'>
            <h3 className='text-ssv_softblack font-ebgaramond text-md mb-2 flex flex-col'>
              <span className='text-sm'>Autor{paso.imagineros.length > 1 && 'es'}:</span> {paso.imagineros.map((imaginero, index) => (
                <span key={index} class="">
                  {imaginero.imaginero}
                  {index !== paso.imagineros.length - 1 && ', '}
                </span>
              ))}
            </h3>
            <h3 className='text-ssv_softblack font-ebgaramond text-md flex flex-col'> 
              <span className='text-sm'>Año:</span> <time>{paso.date}</time>
            </h3>
          </div>
          <figure className='mb-4'>
            <img
              src={`../../images/pasos/${paso.slug}/${paso.slug}@0,25x.jpg`}
              alt={paso.pasoname}
              className='object-cover'
            />
            <figcaption className='text-xs'>
              {paso.picture[0].picauthorattribution
                ? `${paso.picture[0].picdesc} (${paso.picture[0].picauthorattribution})`
                  : `${paso.picture[0].picdesc} / ${paso.picture[0].picauthor}`}
            </figcaption>
          </figure>
          <div className='flex justify-end'>
            <Button
              title='Ver Paso'
              destino={`${paso.slug}`}
              type='primary'
              className='mb-4 md:mb-1'
            />
         </div>
        </article>
      ));
  };

  return (
    <>
      {pasos && loaded && (
        <>
          <SectionHeader name='Pasos' />
          <div className='flex justify-end mb-4'>
            <select value={orden} onChange={handleOrdenChange} className='p-4' style={{ backgroundColor: 'transparent' }}>
              <option value="ascendente">Orden Ascendente</option>
              <option value="descendente">Orden Descendente</option>
            </select>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-9'>
            {renderPasos()}
          </div>
        </>
      )}
      <Loader loaded={loaded} />
    </>
  );
};

export default Pasos;
