import React, { useState, useEffect } from 'react';
import { useContext } from 'react';
import { SsvContext } from '../../context/context';
import SectionHeader from '../../Components/Static/SectionHeader';
import { Button } from '../../Components/Button/Button';
import ReactGA from 'react-ga4';
import Loader from '../../Components/Loader/Loader';
const imagesurl = '../../../images/cofradias/';

const Brotherhoods = () => {
  const { brotherhoods } = useContext(SsvContext);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
    ReactGA.send({ hitType: 'pageview', page: `/cofradias/` });
  }, []);

  function getParsedDateCalendar(date) {
    date = String(date).split(' ');
    const monthNames = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    let days = String(date[0]).split('-');
    const yearCalendar = parseInt(days[0]);
    const mesCalendar = monthNames[parseInt(days[1]) - 1];
    const dayCalendar = parseInt(days[2]);
    const fechatotal = [mesCalendar, dayCalendar, yearCalendar];
    return fechatotal;
  }

  function renderBrotherhoods() {
    return brotherhoods.map((item, index) => {
      if (item.active === true) {
        return (
          <article className='shadow-lg mb-4 flex flex-col rounded-lg content-between p-6 max-w-96' key={JSON.stringify(item)}>
            <div className='divide divide-black mb-3'>
              <h2 className='font-ebgaramond mb-3'>{item.name}</h2>
              <hr className='mb-3' />
            </div>
            <div className='flex flex-col justify-between md:px-4 rounded-lg border-yellow-500 border p-4 mb-4'>
              <h3 className='text-ssv_softblack font-ebgaramond text-md mb-2 flex flex-col'>
                <span className='text-sm'>Sede Canónica:</span> {item.headquarters}
              </h3>
              <h3 className='text-ssv_softblack font-ebgaramond text-md flex flex-col'>
                <span className='text-sm'>Fundación:</span> <time>{getParsedDateCalendar(item.date).join(' ')}</time>
              </h3>
              <p className='text-ssv_softblack w-full font-montserrat'></p>
            </div>
            <figure className='mb-4'>
              <img className='object-cover h-64 w-96 rounded-lg' src={`${imagesurl}${item.slug}/${item.slug}-01.jpeg`} alt='' />
              <figcaption className='text-xs'>
                Autor de la fotografía: <em>{item.picture && item.picture[0] && item.picture[0].picauthor}</em>
              </figcaption>
            </figure>
            <div className='flex justify-end'>
              <Button title='Ver cofradía' destino={`${item.slug}`} type='primary' className='mb-4 md:mb-1' />
            </div>
          </article>
        );
      }
      return null;
    });
  }

  return (
    <>
      {brotherhoods && loaded && (
        <>
          <SectionHeader name='Cofradías' />
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
            {renderBrotherhoods()}
          </div>
        </>
      )}
      <Loader loaded={loaded} />
    </>
  );
};

export default Brotherhoods;
