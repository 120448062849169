export const getParsedDateCalendarPage = (date) => {
  date = String(date).split(' ');
  const monthNames = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  let days = String(date[0]).split('-');
  const yearCalendar = parseInt(days[0]);
  const mesCalendar = monthNames[parseInt(days[1] - 1)];
  const dayCalendar = parseInt(days[2]);
  //const fecha = monthNames[parseInt(days[1])] + '/' + parseInt(days[2]);
  const fechatotal = [mesCalendar, dayCalendar, yearCalendar];
  return fechatotal;
};
