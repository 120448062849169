import React, { useState, useEffect } from 'react';
import Loader from '../Loader/Loader';

const Hero = ({ heroimages }) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  const activeImages = heroimages.filter(item => item.active);

  return (
    <>
      {loaded ? (
        <div
          id='carouselExampleCaptions'
          className='carousel slide carousel-fade transition duration-100 relative'
          data-bs-ride='carousel'>
          <div className='carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4'>
              {activeImages.map((item, index) => (
                <button
                  type='button'
                  data-bs-target='#carouselExampleCaptions'
                  data-bs-slide-to={index}
                  className={index === 0 ? 'active' : ''}
                  aria-current={index === 0 ? 'true' : 'false'}
                  aria-label={`Slide ${index + 1}`}
              ></button>
            ))}
          </div>
          <div className='carousel-inner relative w-full overflow-hidden'>
            {activeImages.map((item, index) => (
              <div
                key={index}
                className={`carousel-item relative float-left w-full h-96 ${
                  index === 0 ? 'active' : ''
                }`}>
                <img
                  src={item.imgurl}
                  className='block w-full h-full object-cover'
                  alt={item.name}
                />
                <div className='carousel-caption md:block absolute text-center bg-black bg-opacity-75 p-4'>
                  <h5 className='text-xl md:text-3xl font-ebgaramond'>
                    {item.name}
                  </h5>
                  <p className='font-montserrat text-xs md:text-base'>
                    {item.author}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <button
            className='carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0'
            type='button'
            data-bs-target='#carouselExampleCaptions'
            data-bs-slide='prev'>
            <span
              className='carousel-control-prev-icon inline-block bg-no-repeat'
              aria-hidden='true'></span>
            <span className='visually-hidden'>Previous</span>
          </button>
          <button
            className='carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0'
            type='button'
            data-bs-target='#carouselExampleCaptions'
            data-bs-slide='next'>
            <span
              className='carousel-control-next-icon inline-block bg-no-repeat'
              aria-hidden='true'></span>
            <span className='visually-hidden'>Next</span>
          </button>
        </div>
      ) : (
        <Loader loaded={loaded} />
      )}
    </>
  );
};

export default Hero;
