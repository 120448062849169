import React from 'react';
import { Link } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';
export const Button = (props) => {
  if (props.type === 'primary') {
    return (
      <div
        className={`py-4 border-b-2 border-ssv_yellow w-fit ${props.className}`}>
        <Link
          to={`${props.destino}`}
          destinytitle={`${props.destinytitle}`}
          className={`font-ebgaramond text-xl flex flex-row text-black`}>
          <span className='mr-4'>{props.title}</span>
          <FaChevronRight className='self-center w-5 h-5 sm:w-6 sm:h-6 dark:text-gray-800' />
        </Link>
      </div>
    );
  } else if (props.type === 'secondary') {
    return (
      <div
        className={`py-4 border-b-2 border-ssv_yellow w-fit ${props.className}`}>
        <Link
          to={`${props.destino}`}
          destinytitle={`${props.destinytitle}`}
          className={`font-ebgaramond text-xl flex flex-row text-white`}>
          <span className='mr-4'>{props.title}</span>
          <FaChevronRight className='w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-white' />
        </Link>
      </div>
    );
  } else if (props.type === 'submit') {
    return (
      <div className={`py-4 border-b-2 border-ssv_yellow w-fit ${props.className}`}>

      <button
        type='submit'
        name={`${props.name}`}
        id={`${props.id}`}
        value={`${props.value}`}
        className={`font-ebgaramond text-xl flex flex-row text-black`}>
        {`${props.title}`}
      </button>
      </div>
    );
  }
};
