import React from 'react';
import Logo from '../../Components/Icons/Logo';
import NavLinksC from './NavLinksC';
import './NavBar.scss';
import MegaMenu from './MegaMenu';
import SuperHeader from './SuperHeader';
 import ReactGA from 'react-ga4';
import { useEffect } from 'react';

const NavBar = () => {
  ReactGA.initialize([
    {
      trackingId: 'G-FRCTYN4GW6',
      gaOptions: {},
      gtagOptions: {},
    },
  ]);
  useEffect(() => {
    ReactGA.initialize('G-FRCTYN4GW6');
  },[]);
  return (
    <>

      <section className='font-montserrat text-white bg-ssv_softwhite sticky top-0 z-10'>
        <SuperHeader></SuperHeader>
        <article className='flex flex-wrap justify-between items-center mx-auto container px-4 md:px-6 py-2.5'>
          <Logo />
          <NavLinksC />
        </article>

        <MegaMenu />
      </section>
    </>
  );
};

export default NavBar;
