import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import SectionHeader from '../../Components/Static/SectionHeader';
import { Button } from '../../Components/Button/Button';

const BASEURL = 'https://ssvback.vercel.app';
//const BASEURL = 'http://localhost:8001';

const ImaginerosDetail = () => {
  const { name } = useParams();
  document.title = `${name}`;
  const [imaginero, setImaginero] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getImagineroByName = async () => {
      const res = await axios.get(`${BASEURL}/imagineros/${name}`);
      setImaginero(res.data.imagineros[0]);
      setTimeout(() => {
        setLoaded(true);
      }, 500);
    };
    getImagineroByName();
  }, []);
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: `/imagineros/${name}` });
  }, [name]);
  return (
    <>
      <div className='flex flex-row p-8'>
        {imaginero ? (
          <>
            {loaded ? (
              <>
                <aside className='w-1/3 p-4 border-r border-black'>
                  <div>
                    <img
                      src={imaginero.picture[0].picurl}
                      alt={`Imagen de ${imaginero.imaginero}`}
                    />
                    <p className='font-montserrat text-sm mb-8 text-ssv_softblack'>
                      {imaginero.imaginero}
                    </p>
                    <h3 className='text-ssv_softblack font-ebgaramond text-xl font-bold leading-10 mb-2'>
                      Obras de {imaginero.imaginero}
                    </h3>
                    <ul className='mb-8'>
                      {imaginero.pasos.map((item) => {
                        return (
                          <li className='font-montserrat text-sm mb-8 text-ssv_softblack'>
                            <Link
                              to={`../pasos/${item.workname}`}
                              className='underline'>
                              {item.workname}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                    <h3 className='text-ssv_softblack font-ebgaramond text-xl font-bold leading-10 mb-2'>
                      Esto es otra sección
                    </h3>
                  </div>
                </aside>
                <div className='w-2/3 p-4'>
                  <section className='border-b border-black'>
                    <SectionHeader
                      name={imaginero.imaginero}
                      className={'text-black p-0 m-0'}
                    />
                    <h2 className='font-ebgaramond'>
                      {imaginero.dateofbirth}-{imaginero.dateofdeath}
                    </h2>
                    <div className='container mx-auto mb-8 flex justify-end'>
                      <Button
                        title='Ir al Listado de Imagineros'
                        destino={'/imagineros'}
                        type='primary'
                        className='mb-1'
                      />
                    </div>
                  </section>
                  <div className='flex'>
                    {imaginero.picture.map((item) => {
                      return (
                        <div className='w-1/3 p-4' key={JSON.stringify(item)}>
                          <img
                            src={item.picurl}
                            alt={item.pictitle}
                            className='w-full aspect-[9/16] object-cover'
                          />
                          <p>{item.pictitle}</p>
                          <p>{item.picdesc}</p>
                          <p>{item.picauthor}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <p>Cargando...</p>
            )}
          </>
        ) : null}
      </div>
    </>
  );
};

export default ImaginerosDetail;
