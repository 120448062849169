import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';
import axios from 'axios';
import SectionHeader from '../../Components/Static/SectionHeader';
import { Button } from '../../Components/Button/Button';
import Loader from '../../Components/Loader/Loader';

const blogpictureurl = '../../../images/blog/';

const BASEURL = 'https://ssvback.vercel.app';
//const BASEURL = 'http://localhost:8001';

const BlogDetail = () => {
  const { slug } = useParams();
  document.title = `${slug}`;
  const [blogArticle, setBlogArticle] = useState({});
  const [loaded, setLoaded] = useState(false);
  setTimeout(() => {
    setLoaded(true);
  }, 500);
  useEffect(() => {
    const getArticleBySlug = async () => {
      const res = await axios.get(`${BASEURL}/blog/${slug}`);
      console.log('res', res);
      setBlogArticle(res.data.blog[0]);
      setTimeout(() => {
        setLoaded(true);
      }, 500);
    };
    getArticleBySlug();
  }, []);
  useEffect (()=> {
    ReactGA.send({ hitType: 'pageview', page: `/blog/${slug}` });
  },[slug])

  return (
    <>
      <div className='flex flex-row justify-center p-8 border-b-2'>
        {blogArticle ? (
          <>
            {loaded ? (
              <>
                <section className='md:w-1/2 p-4 '>
                  <SectionHeader
                    name={blogArticle.title}
                    className={'text-black p-0 m-0'}
                  />
                  <p className='text-2xl bg-ssv_yellow p-8 font-ebgaramond my-8 rounded-md'>
                    {blogArticle.subtitle}
                  </p>
                  <div className='border border-ssv_yellow p-8 my-16 rounded-md'>
                    <h2 className='text-2xl mb-4 font-ebgaramond'>
                      Tabla de contenidos
                    </h2>
                    <ul className='pl-4'>
                      {blogArticle.contents.map((item) => (
                        <li className='mb-2 list-disc'>
                          <a
                            href={`#${item.paragraphtitle}`}
                            className='underline'>
                            {item.paragraphtitle}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {blogArticle.contents.map((item) => (
                    <>
                      <div id={item.paragraphtitle} className='mb-16'>
                        <h2 className='font-ebgaramond mb-8 text-3xl'>
                          {item.paragraphtitle}
                        </h2>
                        <figure className='flex flex-col items-center'>
                          {item.paragraphimage ? (
                            <img
                              src={`${blogpictureurl}${item.paragraphimage}`}
                              alt={item.paragraphtitle}
                              className='h-96 w-96 object-cover'
                            />
                          ) : null}
                          {item.paragraphimagetitle ? (
                            <figcaption className='bg-ssv_yellow p-2 mb-8'>
                              {item.paragraphimagetitle}
                            </figcaption>
                          ) : null}
                        </figure>
                        <p>{item.paragraph}</p>
                      </div>
                    </>
                  ))}
                </section>
              </>
            ) : (
              <Loader loaded={loaded} />
            )}
          </>
        ) : null}
      </div>
      <div className='flex flex-row justify-center p-8'>
        <Button
          title='Ver más artículos'
          destino={'/blog'}
          type='primary'
          className='mb-1'
        />
      </div>
    </>
  );
};

export default BlogDetail;
