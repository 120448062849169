import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import SectionHeader from '../../Components/Static/SectionHeader';
import { Button } from '../../Components/Button/Button';
import Loader from '../../Components/Loader/Loader';
//import { SsvContext } from '../../context/context';
const BASEURL = 'https://ssvback.vercel.app';
//const BASEURL = 'http://localhost:8001';

const PasosDetail = () => {
  const { name } = useParams();
  document.title = `${name}`;
  const [paso, setPaso] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getPasoByName = async () => {
      const res = await axios.get(`${BASEURL}/pasos/${name}`);
      setPaso(res.data.paso[0]);
      console.log('paso es', paso);
      setTimeout(() => {
        setLoaded(true);
      }, 500);
      console.log('paso', paso);
    };
    getPasoByName();
  }, []);
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: `/pasos/${name}` });
  }, [name]);
  // const proccesionesenlasqueaparece = (paso) => {
  //   const { processions } = useContext(SsvContext);  }

  setTimeout(() => {
    setLoaded(true);
  }, 500);
  return (
    <>
      {paso && loaded && (
        <>
          <div className='flex flex-col md:flex-row md:p-8'>
            <aside className='md:w-1/3 p-4 md:pt-16 md:border-r border-black order-2 md:order-1'>
              <p className='font-montserrat text-sm mb-8 text-ssv_softblack'>
                {paso.description}
              </p>
              <h3 className='text-ssv_softblack font-ebgaramond font-bold text-xl leading-10 mb-2'>
                Autor: 
              </h3>
              <ul className='font-montserrat text-sm mb-8 text-ssv_softblack'>
                {paso.imagineros.map((item) => {
                  return (
                    <li
                      className='font-montserrat text-sm mb-4 text-ssv_softblack'
                      key={JSON.stringify(item)}>
                      <Link
                        to={`../imagineros/${item.slug}`}
                        className='underline'>
                        {item.imaginero} 
                      </Link>
                      ({item.dateofbirth}-{item.dateofdeath})
                    </li>
                  );
                })}
              </ul>
              <h3 className='text-ssv_softblack font-ebgaramond font-bold text-xl leading-10 mb-2'>
                Fecha de la obra
              </h3>
              <p className='font-montserrat text-sm mb-8 text-ssv_softblack'>
                {paso.date}
              </p>
              <h3 className='text-ssv_softblack font-ebgaramond font-bold  text-xl leading-10 mb-2'>
                Sede del paso:
              </h3>
              <p className='font-montserrat text-sm mb-8 text-ssv_softblack'>
                {paso.headquarters}
              </p>
              {/* 
              <h3 className='text-ssv_softblack font-ebgaramond font-bold text-xl leading-10 mb-2'>
                Lo puedes ver en las siguientes procesiones:
              </h3>
              <ul className='mb-8'>
                {paso.procession.map((item) => {
                  return (
                    <li
                      className='font-montserrat text-sm mb-4 text-ssv_softblack'
                      key={JSON.stringify(item)}>
                      <Link
                        to={`../procesiones/${item.slug}`}
                        className='underline'>
                        {item}
                      </Link>
                    </li>
                  );
                })}
              </ul>
              */}
            </aside>
            <div className='md:w-2/3 p-4 order-1 md:order-2'>
              <section className='border-b border-black'>
                <SectionHeader
                  name={paso.pasoname}
                  className={'text-black p-0 m-0'}
                />
                <div className='container mx-auto mb-8 flex justify-end'>
                  <Button
                    title='Ir a Listado de pasos'
                    destino={'/pasos'}
                    type='primary'
                    className='mb-1'
                  />
                </div>
              </section>
              <div className='md:flex'>
                {paso.picture.map((item) => (
                  <>
                    {item ? (
                      <>
                        <div
                          className='md:w-1/3 my-4 md:p-4 flex md:block'
                          key={JSON.stringify(item)}>
                          <img
                            src={`../../images/pasos/${paso.slug}/${paso.slug}.jpg`}
                            alt={item.picdesc}
                            className='md:w-full w-1/3 aspect-[9/16] object-cover grayscale hover:grayscale-0 bg-blend-colore'></img>
                          <p className='p-4 md:p-0'>
                            {item.picdesc} - ({item.picauthor})
                          </p>
                        </div>
                      </>
                    ) : null}
                  </>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
      <Loader loaded={loaded} />
    </>
  );
};

export default PasosDetail;
